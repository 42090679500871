<template>
  <v-app>
    <section class="fdb-block" style="background-image: url(imgs/hero/blue.svg);">
      <div class="col-md-6 mx-auto">
        <div class="card rounded shadow-lg">
          <div class="card-body">
            <div class="row text-center">
              <div class="col" style="width:100px; max-height:100px;">
                <a href="#">
                  <img src="/images/siged2.png" @click="cancelarRegistro()" alt="homepage" class="dark-logo" style="max-width:100%; max-height:100%;" />
                </a>
              </div>
            </div>
            <div class="card-title text-center">
              <h2>{{ title }}</h2>
            </div>
            <br>
            <form v-on:submit.prevent="saveItem()">
            <div class="row ">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Nombre (*):</label>
                    <input type="text" class="form-control col-md-9 " v-model="item.nombre" required >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Apellido (*):</label>
                    <input type="text" class="form-control col-md-9" v-model="item.apellido" required >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label ">Correo electrónico (*):</label>
                    <input type="email" class="form-control col-md-9" v-model="item.email" required >
                  </div>
                </div>
              </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Empresa (*):</label>
                    <input type="text" class="form-control col-md-9" v-model="item.empresa" required >
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Teléfono (*):</label>
                    <input type="text" class="form-control col-md-9" v-model="item.telefono" required >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Ciudad (*):</label>
                    <input type="text" class="form-control col-md-9" v-model="item.ciudad" required >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Contraseña (*):</label>
                    <input type="password" class="form-control col-md-9" v-model="item.contrasena" required >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Confirmar (*):</label>
                    <input type="password" class="form-control col-md-9" v-model="item.confirmar" required>
                  </div>
                </div>
              </div> 
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="chequeAutorizar" required >
                <label class="form-check-label" for="chequeAutorizar">Acepto los <a href="/tyc" target="_blank" class="text-primary">términos y condiciones del sitio</a> y la <a href="http://www.siged.co/politicas-de-privacidad/" target="_blank" class="text-primary">política de tratamiento de datos personales</a></label>
              </div>
              <br>
              <div class="text-center">
                <p class="msg">{{ message }}</p>
              </div>
              <div class="row text-center">
                <div class="col-6">
                  <button type="submit" class="btn btn-primary float-center">{{ buttontext }}</button>
                </div>
                <div class="col-6">
                  <button type="button" class="btn btn-secondary" v-on:click="cancelarRegistro()">Cancelar</button>
                </div>            
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalForm :visible="show" @close="show=false" :message="'Por favor espere mientras se guardan los datos..'" />
    </section>
  </v-app>
</template>

<script>
  //import { VApp } from 'vuetify/lib';

  //import toastr from 'toastr';
  import { wait } from '../../utils';
  import ModalForm from '../modal/ModalForm.vue';

  export default {
    components: {
      //VApp,
      ModalForm
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},       
        message: '',
        buttontext: '',
        show: false
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {        
      this.title = 'Registrarse';
      this.buttontext = 'Registrarse';
    },
    methods: {
      showModal(value){
        this.$store.commit('setModal', value);
      },
      setClose(close){
        this.close = close;
      },
      saveItem(){
        this.show = true;
        var clientes={};
        clientes.nombre=this.item.nombre;
        clientes.apellido=this.item.apellido;
        clientes.email= this.item.email.toLowerCase(); 
        clientes.empresa=this.item.empresa;          
        clientes.direccion='x';          
        clientes.telefono=this.item.telefono;
        clientes.ciudad=this.item.ciudad;
        clientes.contrasena=this.item.contrasena;
       
        if (this.item.contrasena != this.item.confirmar) {
          this.message = '¡Error la contraseña es distinta a la confimacion';
          this.show = false;
          return;
        }    

        if (this.item.contrasena.length < 8){
          this.message = '¡Advertencia. La contraseña debe tener una longitud mínima de 8 caracteres';
          this.show = false;
          return;
        }

        if (this.chequeAutorizar) {
          this.message = 'Por favor acepte los términos y condiciones del sitio y la política de tratamientos de datos personales';
          this.show = false;
          return;
        } 
        
        let uri = '/clientes/add_signup';
        this.axios.post(uri, clientes)
        //.then(wait(5000))
        .then(response => {
          var clienteid = response.data.id;
          var datos = {};
          datos.action = 'signup';
          datos.id = clienteid;
          datos.email = this.item.email.toLowerCase();
          datos.nombre = this.item.nombre;
          datos.apellido = this.item.apellido;
          datos.empresa = this.item.empresa;
          datos.host = window.location.origin;

          //alert(datos.email);
          let uri = "/api/email";
          this.axios.post(uri, datos)
          .then(response => {
            console.log(response)
            this.$router.replace({ name: 'Success', params: { email: datos.email } });                                                     
            })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al enviar correo' + err;
            this.show = false;
          });    
         }) 
        .catch(err => {
          // Error al crear el Cliente
          this.message = err.response.data.message;
          this.show = false;
        });  
            
      }, 
      cancelarRegistro(){
        if (this.$route.params.web){
          window.open("https://www.siged.co", "_self");
        } else {
          this.$router.replace({ name: 'Signin' });
        }
      }
     
    }
  } 
  </script>

  <style>
    .msg {
      color: #ff0000;
      background-color: white;
    }
    .app-bg {
      background:#BBDEFB;
    }
  </style>
