// utils to delay promise
exports.wait = function (ms) {
  return (x) => {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

// Días transcurridos entre dos fechas restando sábados, domingos y dias no laborales
exports.tiempoUtilizado = function (inicio, fin, dnl) {
  var fi = new Date(inicio);
  var t = new Date(fi.getFullYear(), fi.getMonth(), fi.getDate(), 0, 0, 0);
  var ff = new Date(fin);
  var t2 = new Date(ff.getFullYear(), ff.getMonth(), ff.getDate(), 0, 0, 0);
  var dif = 0; //diferencia de días

  //var dnl = [ '2020-01-01', '2020-01-06', '2020-03-23', '2020-04-09', '2020-04-10', '2020-05-01', '2020-05-25', '2020-06-15', '2020-06-22', '2020-06-29', '2020-07-20', '2020-08-07', '2020-08-17', '2020-10-12', '2020-11-02', '2020-11-16', '2020-12-08', '2020-12-25', '2021-01-01', '2021-01-11', '2021-03-22', '2021-04-01', '2021-04-02', '2021-05-01', '2021-05-17', '2021-06-07', '2021-06-14', '2021-07-05', '2021-07-20', '2021-08-07', '2021-08-16', '2021-10-18', '2021-11-01', '2021-11-15', '2021-12-08', '2021-12-25' ];

  while (t < t2) {
    t.setDate(t.getDate() + 1);
    if (t.getDay() != 0 && t.getDay() != 6) { // Si es diferente a domingo y sábado
      let e = false; //existe?

      for (let i = 0; i < dnl.length; i++) {
        let d = new Date(dnl[i]);
        if (d.getTimezoneOffset() > 0) {
          d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
        }
        if (t.getTime() == d.getTime()) {
          e = true;
          i = dnl.length;
        }
      }

      if (!e) {
        dif++;
      }
    }
  }
  return dif;
}

exports.addDaysWithoutHolidays = ({ date_start = null, days = 0, dnl = [] }) => {
  var fi = new Date(date_start);
  var t = new Date(fi.getFullYear(), fi.getMonth(), fi.getDate() + 1, 0, 0, 0);

  var ff = fi;

  var n = 0;

  while (n < days) {
    t.setDate(t.getDate() + 1);
    if (t.getDay() != 0 && t.getDay() != 6) {

      let find_date = dnl.find(e => {
        let d = new Date(e);

        if (d.getTimezoneOffset() > 0) {
          d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
        }

        if (t.getTime() == d.getTime()) {
          return e
        }
      })

      if (!find_date) {
        ff = t;
        n++;
      }
    }
  }


  return ff.toLocaleDateString().split("/").reverse().join("-")
}